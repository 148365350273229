import React from 'react';
import styled from 'styled-components/macro'

const Layout = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  font-family: Open Sans, sans-serif;
  background-color: #232129;
  color:  #f9f9f9;
`

export default ({ children }) => (
  <Layout>{children}</Layout>
)